<template>
    <section>
        <div class="row mx-0 f-18 text-orange f-600 mt-3">
            <div class="col text-general">
                Cuentas por cobrar en los próximos 2 meses
            </div>
        </div>
        <graficaMeses />
        <div v-loading="loading" class="row mx-0 mt-4 justify-center">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 px-1">
                <div class="row mx-0 h-100 align-items-center">
                    <div class="col border mx-1 bg-light-f5 text-general2 box-shadow-29 br-12 py-2">
                        <p class="text-center f-15">
                            Cuotas Vencidas
                        </p>
                        <p class="text-center f-18 f-600">
                            {{ info.cuotas_vencidas }}
                        </p>
                    </div>
                    <div class="col border mx-1 bg-light-f5 text-general2 box-shadow-29 br-12 py-2">
                        <p class="text-center f-15">
                            Deuda vencida
                        </p>
                        <p class="text-center f-18 f-600">
                            {{ separadorNumero(info.deuda_vencidas) }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-11 deuda px-1 mx-1 bg-general3 text-white box-shadow-29 br-12 py-3">
                <p class="text-center f-15">
                    Deuda total
                </p>
                <p class="text-center f-18 f-600">
                    {{ separadorNumero(info.deuda_total) }}
                </p>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 px-1">
                <div class="row mx-0 h-100 align-items-center">
                    <div class="col border mx-1 bg-light-f5 text-general2 box-shadow-29 br-12 py-2">
                        <p class="text-center f-15">
                            Próximas Cuotas
                        </p>
                        <p class="text-center f-18 f-600">
                            {{ info.proxi_cuotas }}
                        </p>
                    </div>
                    <div class="col border mx-1 bg-light-f5 text-general2 box-shadow-29 br-12 py-2">
                        <p class="text-center f-15">
                            Deuda por cobrar
                        </p>
                        <p class="text-center f-18 f-600">
                            {{ separadorNumero(info.deuda_por_cobrar) }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 f-18 mt-5">
            <div class="col-12 f-600 text-general">
                Cuentas pendientes
            </div>
            <div class="col">
                <tabla-general v-loading="loading" :data="tableData" :columnas="dataColumnas">
                    <template slot="adicionales-izquierda">
                        <el-table-column prop="nombre" label="Cliente" sortable min-width="200" class-name="f-16 f-600">
                            <template slot-scope="scope">
                                <p class="cr-pointer">
                                    {{ scope.row.nombre }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="id_pedido_admin" label="Pedido" sortable width="90">
                            <template slot-scope="scope">
                                {{ scope.row.id_pedido }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="valor" label="Valor del pedido" sortable min-width="100" class-name="text-right">
                            <template slot-scope="scope">
                                {{ separadorNumero(scope.row.valor_final) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="fecha_cuota" label="Fecha del pedido" sortable width="180" class-name="text-center">
                            <template slot-scope="scope">
                                <p>{{ formatearFecha(scope.row.created_at_pedido,'DD MMM Y') }}</p>
                            </template>
                        </el-table-column>
                    </template>
                    <template v-slot:adicionales-derecha>
                        <el-table-column prop="valor" label="Valor del pedido" sortable min-width="100" class-name="text-right">
                            <template slot-scope="scope">
                                {{ separadorNumero(scope.row.valor_pendiente) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="fecha_cuota" label="Fecha Cuota" sortable width="180">
                            <template slot-scope="scope">
                                <p :class="scope.row.vencido?'text-danger-fecha': ''">{{ formatearFecha(scope.row.fecha,'DD MMM Y') }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column width="180">
                            <template slot-scope="scope">
                                <div v-if="$can('boton_leecheros_creditos_pagos')" class="bg-gr-general br-12 px-2 py-1 text-white text-center cr-pointer" style="" @click="AbrirResgistrarPago(scope.row)">
                                    Registrar pago
                                </div>
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
        </div>
        <!-- Modales -->
        <modalRegistrarPagos ref="RegistrarPago" />
    </section>
</template>

<script>
import Creditos from '~/services/almacen/creditos'
import {mapGetters} from 'vuex'


export default {
    components: {
        modalRegistrarPagos: () => import('~/pages/almacen/creditos/partials/modalRegistrarPago'),
        graficaMeses: () => import('~/pages/almacen/creditos/components/graficaMeses')

    },
    data(){
        return{
            loading: false,
            dataColumnas: [
                'nombre', 'id_pedido_admin','valor_final','valor_pendiente','fecha','created_at_pedido'
            ],
            // info:{
            //     cuotas_vencidas: 0,
            //     deuda_por_cobrar: 0,
            //     deuda_vencidas: 0,
            //     proxi_cuotas: 0,
            //     deuda_total: 0
            // },
            /* tableData: [
                { nombre: 'OXXO', deuda: '$ 534.000', valor_pedido: '01 Jul. 2018', fecha_pedido: '01 Jul. 2018', proximo_pago: '05 Jul. 2008' },
            ], */
        }
    },
    computed:{
        ...mapGetters({
            info: 'almacen/credito/infoTabCuotas',
            tableData: 'almacen/credito/tablaCuotas'
        }),
    },
    mounted(){
        this.listarCuotas()
    },
    methods: {
        AbrirResgistrarPago(row){
            this.$refs.RegistrarPago.toggle(row, 2); // 2 desde tendero
        },
        async listarCuotas(){
            try {

                this.loading = true
                await this.$store.dispatch('almacen/credito/action_listar_cuotas', 2) // 2 indica que es desde tendero
                this.loading = false
            } catch (e){
                this.loading = false
                this.error_catch(e)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.box-shadow-29{
    box-shadow: 0px 3px 6px #00000029;
}
.br-12{
    border-radius: 12px;
}
.text-danger-fecha{
    color: #FF0000;
}

/* Media Queries */

@media (max-width:767px) {
    .row .deuda{
        margin-top: 8px;
        margin-bottom: 8px;
    }    
}
</style>
